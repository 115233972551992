import { useRef, useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import { tabChampLib3_2 } from "./Tableaux";
import { customStyles } from "./StylesDataTable3";
import Cr4Compo from "./Cr4Compo";
import {
  siDateJour,
  dateJJMMAAAA,
  spiSearch,
  isEmpty,
  isArrPla,
  isArrGag,
  heuCrs,
  siUserMe,
  cr3LibCrs,
} from "./Utils";
import { getPa2sByDrc, updPa2SelIn } from "../actions/pa2.actions";
import {
  getNotByCheId,
  addNotByCheId,
  putNotByCheId,
} from "../actions/not.actions";
import { useAuth0 } from "@auth0/auth0-react";

const initialValue = {
  not_che_id: 0,
  not_pa2_id: 0,
  not_not: "",
  not_drc: "",
  not_top: "",
};

const Cr3Compo = ({ cr3Exp }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [notModif, setNotModif] = useState(false);
  const dispatch = useDispatch();
  const [cr3, setCr3] = useState();
  const [notItems, setNotItems] = useState(initialValue);
  const [notItemsMod, setNotItemsMod] = useState();
  const cr3Sel = useSelector((state) => state.pa2Reducer);
  const not = useSelector((state) => state.notReducer);
  //const che = useSelector((state) => state.cheReducer);
  const { user, isAuthenticated } = useAuth0();
  const jj = useRef(0);
  const nbp = useRef(0);
  const [rapPopup, setRapPopup] = useState(false);

  if (
    cr3Exp[2].value === "3" ||
    (cr3Exp[2].value === "search" && cr3Exp[2].id === 2)
  ) {
    jj.current = 2;
    //nbp.current = cr3.length;
    nbp.current = 0;
  } else {
    //jj.current = 2;
    nbp.current = cr3Exp[3];
  }

  useEffect(() => {
    if (isLoading) {
      if (cr3Exp[2].id === 2 && cr3Exp[2].value === "search") {
        cr3Exp[2].label = cr3LibCrs(cr3Exp, "cr2");
        let ra2Drc = cr3Exp[0].data.cr2_drc;
        let partants = cr3Exp[3];
        setCr3(
          partants.filter((par) => {
            return par.pa2_drc.toLowerCase().includes(ra2Drc.toLowerCase());
          })
        );
        cr3Exp[2].value = "33";
        setIsLoading(false);
      } else if (cr3Exp[2].value === "3") {
        cr3Exp[2].label = cr3LibCrs(cr3Exp, "ra2");
        let ra2Drc = cr3Exp[0].data.ra2_drc;
        let partants = cr3Exp[1][1];
        setCr3(
          partants.filter((par) => {
            return par.pa2_drc.toLowerCase().includes(ra2Drc.toLowerCase());
          })
        );
        setIsLoading(false);
      } else if (cr3Exp[2].id === 1 && cr3Exp[2].value === "search") {
        setCr3(cr3Exp[0]);
      }
    }
  }, [cr3, cr3Exp, isLoading]);

  //Chargement tableaux
  const nameChamp = useMemo(
    () => tabChampLib3_2("nameChamp", jj.current, cr3Exp[2].id),
    [cr3Exp]
  );
  const libChampCourt = useMemo(
    () => tabChampLib3_2("libChampCourt", jj.current, cr3Exp[2].id),
    [cr3Exp]
  );
  const widChamp = useMemo(
    () => tabChampLib3_2("widChamp", jj.current, cr3Exp[2].id),
    [cr3Exp]
  );
  const tabSortable = useMemo(
    () => Array.from(nameChamp, () => true),
    [nameChamp]
  );

  let iLibChampLongMax = nameChamp.length;
  let userMeOut = isAuthenticated && siUserMe(user);
  if (userMeOut === false) iLibChampLongMax = iLibChampLongMax - 1;
  const columns = Array.from({ length: iLibChampLongMax }, (v, i) => {
    return {
      name: libChampCourt[i],
      selector: (row) => row[nameChamp[i]],

      cell: (row) => {
        if (
          nameChamp[i] === "cr2_heu" &&
          (siDateJour(row.cr2_dtm) || row.cr2_dtm > "2029-12-31")
        ) {
          return (
            <div className="card-container_1">
              <button>
                {row.cr2_reu}-C{row.cr2_crs} à {heuCrs(row.cr2_heu.toString())}
              </button>
            </div>
          );
        } else if (nameChamp[i] === "cr2_heu" && !siDateJour(row.cr2_dtm)) {
          return (
            <div className="card-container_h">
              <button>
                {dateJJMMAAAA(row.cr2_dtm)}-{row.cr2_reu}-C{row.cr2_crs}
              </button>
            </div>
          );
        } else if (nameChamp[i] === "pa2_num") {
          return (
            <div className="card-left-3">
              <button
                style={{
                  background: isAuthenticated && row.pa2_ecn === "n" && "red",
                }}
              >
                {row.pa2_num}
              </button>
            </div>
          );
        } else if (nameChamp[i] === "che_nom") {
          let siDrcNumC = "";
          if (
            cr3Exp[2].value !== "3" &&
            cr3Exp[2].value !== "33" &&
            !isEmpty(cr3Exp[1][2])
          ) {
            siDrcNumC = spiSearch("C", cr3Exp[1][2], row.pa2_drc, row.pa2_num);
          }
          return (
            <div className="card-container_1">
              <button
                className="btn btn-success"
                onClick={() => handleClicknote(row)}
                style={{
                  minWidth: "150",
                  color:
                    isAuthenticated &&
                    (siDrcNumC === true ||
                      row.pa2_cpr === "p" ||
                      row.pa2_sel === "O") &&
                    "red",
                }}
              >
                {row.che_nom.toLowerCase()}
              </button>
            </div>
          );
        } else if (nameChamp[i] === "joc_nom") {
          let siDrcNumJ = "";
          if (!isEmpty(cr3Exp[1][0])) {
            siDrcNumJ = spiSearch("J", cr3Exp[1][0], row.pa2_drc, row.pa2_num);
          }
          return (
            <p
              style={{
                minWidth: "150",
                color:
                  isAuthenticated &&
                  (siDrcNumJ === true || row.pa2_ecj === "j") &&
                  "red",
              }}
            >
              {row.joc_nom.toLowerCase().substr(0, 22)}
            </p>
          );
        } else if (nameChamp[i] === "ent_nom") {
          let siDrcNumE = "";
          if (
            cr3Exp[2].value !== "3" &&
            cr3Exp[2].value !== "33" &&
            !isEmpty(cr3Exp[1][1])
          ) {
            siDrcNumE = spiSearch("E", cr3Exp[1][1], row.pa2_drc, row.pa2_num);
          }
          return (
            <p
              style={{
                minWidth: "150",
                color:
                  isAuthenticated &&
                  (siDrcNumE === true || row.pa2_ece === "e") &&
                  "red",
              }}
            >
              {row.ent_nom.toLowerCase().substr(0, 22)}
            </p>
          );
        } else if (nameChamp[i] === "pa2_dis") {
          return (
            <p
              style={{
                color:
                  row.pa2_dis !== row.cr2_di2 &&
                  row.cr2_cat === "a" &&
                  row.pa2_coteq !== 999.9
                    ? "#C70039"
                    : "black",
              }}
            >
              {row.pa2_dis}
            </p>
          );
        } else if (nameChamp[i] === "pa2_faveq") {
          return (
            <p
              style={{
                background: cr3Exp[2].value === "fv1" && "red",
              }}
            >
              {row.pa2_faveq}/{row.cr2_nbp}
            </p>
          );
        } else if (nameChamp[i] === "pa2_faveq") {
          return (
            <p
              style={{
                background: cr3Exp[2].value === "fv1" && "red",
              }}
            >
              {row.pa2_faveq}/{row.cr2_nbp}
            </p>
          );
        } else if (nameChamp[i] === "pa2_arreq") {
          return (
            <p
              style={{
                background: cr3Exp[2].value === "s1g" && "red",
              }}
            >
              {row.pa2_arreq}
            </p>
          );
        } else if (nameChamp[i] === "ra2_s1geq") {
          return isArrGag(row.pa2_arreq, row.ra2_s1geq);
        } else if (nameChamp[i] === "ra2_plaeq") {
          return isArrPla(
            row.pa2_arreq,
            row.ra2_s1peq,
            row.ra2_s2peq,
            row.ra2_s3peq
          );
        } else if (nameChamp[i] === "ra2_s1g") {
          return isArrGag(row.pa2_arreq, row.ra2_s1g);
        } else if (nameChamp[i] === "ra2_pla") {
          return isArrPla(row.pa2_arr, row.ra2_s1p, row.ra2_s2p, row.ra2_s3p);
        } else {
          return row[nameChamp[i]];
        }
      },

      sortable: tabSortable[i],
      width: widChamp[i],
    };
  });

  const conditionalRowStyles = [
    {
      when: (row) => row.pa2_coteq === 999.9,
      style: {
        backgroundColor: "#ff7b77",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    // You can also pass a callback to style for additional customization
    {
      when: (row) => row.calories < 300,
      style: (row) => ({
        backgroundColor: row.isSpecia ? "pink" : "inerit",
      }),
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Lignes par page",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Tous",
    //paginationPerPage: 20,
    // paginationRowsPerPageOptions: [20, 30, 40, 50]
  };

  const fixedHeader = {
    fixedHeader: true,
    fixedHeaderScrollHeight: "100px",
  };

  const handleClicknote = (rowPa2) => {
    dispatch(getNotByCheId(rowPa2.pa2_che_id));
    setNotItemsMod(not);
    if (!isEmpty(not)) {
      // si modif
      setNotModif(true);
      <handlePopupSubmit />;
      //notItems.not_not = rowPa2.pa2_che_id;
    } else {
      // si création
      setNotModif(false);
      notItems.not_che_id = rowPa2.pa2_che_id;
      notItems.not_pa2_id = rowPa2.pa2_id;
      notItems.not_nom = rowPa2.che_nom;
      notItems.not_drc = rowPa2.pa2_drc;
      <handlePopupSubmit />;
    }

    if (notModif) {
    }

    if (rapPopup === true) {
      setRapPopup(false);
    } else {
      setRapPopup(true);
    }
  };

  const handleInputChange = (e) => {
    //alert(`The name you entered was:` + e.target.value);
    setNotItems({ ...notItems, [e.target.name]: e.target.value });
  };

  const handlePopupSubmit = () => {
    if (!isEmpty(not)) {
      dispatch(putNotByCheId(notItems.not_che_id, notItems.not_not));
    } else {
      dispatch(
        addNotByCheId(
          notItems.not_che_id,
          notItems.not_pa2_id,
          notItems.not_not
        )
      );
      dispatch(updPa2SelIn(notItems.not_pa2_id));
    }
    dispatch(getPa2sByDrc(notItems.not_drc));
    setCr3(cr3Sel);
    setIsLoading(true);
  };

  return isAuthenticated && siUserMe(user) ? (
    <>
      <DataTable
        title={cr3Exp[2].label}
        data={cr3}
        highlightOnHover
        pointerOnHover
        columns={columns}
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
        fixedHeader={fixedHeader}
        defaultSortField="cr2_heu"
        striped
        responsive
        pagination
        paginationComponentOptions={paginationComponentOptions}
        paginationRowsPerPageOptions={[10, 40, 60, 100]}
        paginationPerPage={40}
        footerBold={true}
        noDataComponent="Aucun résultat"
        keyField="pa2_id"
        expandOnRowClicked={true}
        expandableRows
        //expandableRowDisabled={conditionalRowDisabled}
        expandableRowsComponent={(row) =>
          cr3Exp[2].value === "3" || cr3Exp[2].value === "33" ? (
            <Cr4Compo cr4Exp={row} />
          ) : (
            <Cr3Compo
              cr3Exp={[row, cr3Exp[1], { id: 2, value: "search" }, cr3Exp[3]]}
            />
          )
        }
      />

      {rapPopup && !isLoading && (
        <div className="popup-profil-container">
          <div className="modal">
            <h3>
              La note de : {notModif ? notItemsMod.not_nom : notItems.not_nom}
            </h3>
            <span className="cross" onClick={() => setRapPopup(false)}>
              &#10005;
            </span>

            <h4>{notModif ? "Modification" : "Création"}</h4>

            <p>
              <label for="formNot">Note</label>
            </p>
            {notModif ? (
              <>
                <textarea
                  rows="9"
                  cols="50"
                  name="not_not"
                  defaultValue={notItemsMod[0].not_not}
                  onChange={handleInputChange}
                ></textarea>
                <input
                  type="hidden"
                  name="not_che_id"
                  defaultValue={notItemsMod[0].not_che_id}
                ></input>
              </>
            ) : (
              //création
              <>
                <textarea
                  rows="9"
                  cols="50"
                  name="not_not"
                  onChange={handleInputChange}
                ></textarea>

                <input
                  type="hidden"
                  name="not_nom"
                  defaultValue={notItems.not_nom}
                ></input>
                <input
                  type="hidden"
                  name="not_che_id"
                  defaultValue={notItems.not_che_id}
                ></input>
                <input
                  type="hidden"
                  name="not_pa2_id"
                  defaultValue={notItems.not_pa2_id}
                ></input>
                <input
                  type="hidden"
                  name="not_drc"
                  defaultValue={notItems.not_drc}
                ></input>
              </>
            )}

            <button onClick={handlePopupSubmit}>Valider</button>
          </div>
        </div>
      )}
    </>
  ) : (
    <DataTable
      title={cr3Exp[2].label}
      data={cr3}
      highlightOnHover
      pointerOnHover
      columns={columns}
      customStyles={customStyles}
      conditionalRowStyles={conditionalRowStyles}
      fixedHeader={fixedHeader}
      defaultSortField="cr2_heu"
      striped
      responsive
      pagination
      paginationComponentOptions={paginationComponentOptions}
      paginationRowsPerPageOptions={[10, 40, 60, 100]}
      paginationPerPage={40}
      footerBold={true}
      noDataComponent="Aucun résultat"
      keyField="pa2_id"
      expandOnRowClicked={true}
      expandableRows
      //expandableRowDisabled={conditionalRowDisabled}
      expandableRowsComponent={(row) =>
        cr3Exp[2].value === "3" || cr3Exp[2].value === "33" ? (
          <Cr4Compo cr4Exp={row} />
        ) : (
          <Cr3Compo
            cr3Exp={[row, cr3Exp[1], { id: 2, value: "search" }, cr3Exp[3]]}
          />
        )
      }
    />
  );
};

export default Cr3Compo;
